import { api } from '..';
import {
  CallsReportData,
  CybersourceReportData,
  ListReportData,
  OrderReportData,
  PointReportData,
  UsersReportData,
} from './types';

export const reportApi = api.enhanceEndpoints({ addTagTypes: ['Report'] }).injectEndpoints({
  endpoints: build => ({
    exportPointReport: build.query<PointReportData, { fromDate: string; toDate: string; userID: string }>({
      query: ({ fromDate, toDate, userID }) => ({
        url: `v1/report/export/points?fromDate=${fromDate}&toDate=${toDate}${userID ? `&userID=${userID}` : ''}`,
        method: 'GET',
      }),
    }),
    exportOrderReport: build.query<
      OrderReportData,
      { fromDate: string; toDate: string; format: string; state: string }
    >({
      query: ({ fromDate, toDate, format, state }) => ({
        url: `v1/report/export/order?fromDate=${fromDate}&toDate=${toDate}&format=${format}&state=${state}`,
        method: 'GET',
      }),
    }),
    exportActivityAccessReport: build.query<UsersReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v1/report/export/activity-access?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    exportActivityRegisterReport: build.query<UsersReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v1/report/export/activity-register?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    exportPromotionalProductReport: build.query<
      UsersReportData,
      { fromDate: string; toDate: string; promoProductId: number; channel: string }
    >({
      query: ({ fromDate, toDate, promoProductId, channel }) => ({
        url: `v1/report/export/promotional-product`,
        params: {
          fromDate,
          toDate,
          promoProductId,
          channel,
        },
        method: 'GET',
      }),
    }),
    getReportListV1: build.query<ListReportData, any>({
      query: () => ({
        url: `v1/report`,
        method: 'GET',
      }),
    }),
    exportActivityOrderReport: build.query<UsersReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v1/report/export/activity-order?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    exportCybersourceReport: build.query<CybersourceReportData, { fromDate: string; toDate: string; platform: string }>(
      {
        query: ({ fromDate, toDate, platform }) => ({
          url: `v1/report/export/cybersource?fromDate=${fromDate}&toDate=${toDate}&platform=${platform}`,
          method: 'GET',
        }),
      },
    ),
    exportCallsReport: build.query<CallsReportData, { fromDate: string; toDate: string }>({
      query: ({ fromDate, toDate }) => ({
        url: `v1/report/export/customer-calls?fromDate=${fromDate}&toDate=${toDate}`,
        method: 'GET',
      }),
    }),
    downloadReport: build.query<Blob, { filename: string }>({
      query: ({ filename }) => ({
        url: `v1/report/download/${filename}`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),
  }),
});

export const {
  useLazyExportPointReportQuery,
  useLazyExportOrderReportQuery,
  useLazyExportActivityAccessReportQuery,
  useLazyExportActivityRegisterReportQuery,
  useLazyExportActivityOrderReportQuery,
  useLazyExportCybersourceReportQuery,
  useLazyExportCallsReportQuery,
  useLazyDownloadReportQuery,
  useLazyGetReportListV1Query,
  useLazyExportPromotionalProductReportQuery,
} = reportApi;
