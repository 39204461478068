import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { NavLink } from 'react-router-dom';
import toast from '../../../components/Basic/Toast';
import AnalyticalDownloadTable from './AnalyticalDownloadTable';
import { Search } from '@material-ui/icons';
import Loading from '../../../components/Basic/Loading';
import { useAppDispatch, useAppSelector } from 'utilities/redux';
import { useLazyDownloadReportQuery, useLazyGetReportListV1Query } from 'core/report/ReportService';
import { setListReportData } from 'core/report/ReportSlice';

const AnalyticalDownload = ({ intl }) => {
  const { listReport } = useAppSelector(state => state.reportReducer);

  const [list, setList] = useState(listReport);

  const dispatch = useAppDispatch();

  const [downloadReport] = useLazyDownloadReportQuery();
  const [getReportsListV1] = useLazyGetReportListV1Query();

  useEffect(() => {
    setLoader(true);
    reportsList();
  }, []);

  const reportsList = async () => {
    try {
      const res = await getReportsListV1({}).unwrap();
      dispatch(setListReportData({ listReport: res }));
      setList(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const [loader, setLoader] = useState(false);

  const filenameRef = useRef('');

  const pressEnter = e => {
    if (e.keyCode === 13) {
      setList({
        rows: listReport.rows.filter(item => item.includes(filenameRef.current.input.value)),
        count: listReport.rows.filter(item => item.includes(filenameRef.current.input.value)).length,
      });
    }
  };

  const handleDownload = async filename => {
    try {
      const res = await downloadReport({ filename: filename }).unwrap();

      const blob = res;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      toast.error({ title: `File ${filename} is in processing export or not existing!` });
    }
  };

  return (
    <div>
      <div className={'analytical_layout_content_box_inside'}>
        <div className={'analytical_layout_content_box_inside_top'}>
          <span>
            <FormattedMessage id="Download" />
          </span>
          <p>
            <span>
              <FormattedMessage id="Download export file" />
            </span>
          </p>
        </div>
        <div className={'analytical_layout_content_box_inside_bottom'}>
          <div className={'analytical_layout_download'}>
            <Loading visible={loader} />
            <Input
              suffix={<Search />}
              className={'input_id'}
              placeholder="customer_reports_123456789.csv"
              ref={filenameRef}
              onKeyDown={pressEnter}
            />
            <AnalyticalDownloadTable
              filesList={list.rows || []}
              total={list.count}
              handlerDownloadFile={handleDownload}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AnalyticalDownload.propsTypes = {
  intl: typeof useIntl,
  downloadReport: PropTypes.func.isRequired,
  getReportsList: PropTypes.func.isRequired,
};

AnalyticalDownload.defaultProps = {
  listReport: {},
};

export default compose(injectIntl)(AnalyticalDownload);
